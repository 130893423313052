<template>
  <table class="table text-center table-bordered table-sm text-dark mb-1">
    <thead>
    <tr class="text-center">
      <th class="lead e-bg-green-light-3">Description</th>
      <th v-for=" (year,index) in years " :class="{
        'lead bg-bfbfbf' : index ===0,
        'lead bg-a6a6a6' : index ===1,
        'lead bg-ffd966' : index ===2,
        'lead e-bg-yellow' : index ===3,
        'lead bg-f7caac' : index ===4,
        'lead bg-f4b083' : index ===5,
        'lead bg-042454' : index ===6,
        'lead bg-1b4f72' : index ===7,
        'lead bg-056cb4' : index ===8,
        'lead bg-478bcA' : index ===9,
        'lead bg-ff0000' : index ===10,
      }"> {{year}}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in data">
      <td>{{item.event}}</td>
      <td v-for="(year) in years">
        {{item.years[year]}}
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "AccidentIncidentYearlyTable",
  props:{
    data:{
      type:Object | Array,
      default:null
    },
    years:{
      type:Object | Array,
      default: null
    },
  }
}
</script>

<style scoped>

</style>
