<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <h4 class="mb-3 text-center">Overview of PSC deficiency</h4>
          <div class="row mb-2 justify-content-end" v-if="!loading">
            <div class="col-auto">
              <a class="btn btn-dark btn-sm force-white-all" href="#" @click.prevent="downloadReport"><font-awesome-icon icon="download" class="mr-2"></font-awesome-icon>DOWNLOAD EXCEL</a>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card shadow-sm mb-4">
                <div class="card-body pb-2">
                  <template v-if="loading">
                    <app-loading></app-loading>
                  </template>
                  <div v-else>
                    <psc-yearly-chart :series="chartInfo" :categories="chartCategories"></psc-yearly-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4 class="mb-3 text-center">PSC deficiency Data</h4>
          <div class="row">
            <div class="col-12">
              <div class="card shadow-sm mb-4">
                <div class="card-body pb-2">
                  <template v-if="loading">
                    <app-loading></app-loading>
                  </template>
                  <div v-else>
                    <!--                    <psc-yearly-table :series="chartInfo" :categories="chartCategories"></psc-yearly-table>-->
                    <psc-yearly-table :data="inspectionDeficiencyCodeGroup" :years="years"></psc-yearly-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      <p class="lead text-black-50 mb-0">Number of deficiency by Deficiency Code Group</p>-->
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AppLoading from "@/components/elements/AppLoading.vue";
import PscDeficiencyCodeGroupChart from "@/components/dashboard/psc/PscDeficiencyCodeGroupChart.vue";
import PscDeficiencyCodeGroupTable from "@/components/dashboard/psc/PscDeficiencyCodeGroupTable.vue";
import {PSC_REPORT_INDEX} from "@/services/ConstantService";
import {SERVER_API_URL, SERVER_WS_HOST} from "@/services/ServerConstant";
import {UrlService} from "@/services/UrlService";
import axios from "axios";
import {GlobalService} from "@/services/GlobalService";

export default {
  name: "PscDeficiencySummary",
  data() {
    return {
      startYear: null,
      currentYear: null,
      loading: false,
      downloadExcelUrl: new URL(PSC_REPORT_INDEX + '/' + 'deficiency-code-group?export=excel', SERVER_API_URL)
    }
  },
  components: {
    'psc-yearly-chart': PscDeficiencyCodeGroupChart,
    'psc-yearly-table': PscDeficiencyCodeGroupTable,
    'app-loading': AppLoading
  },
  computed: {
    ...mapGetters(['inspectionDeficiencyCodeGroup']),
    years() {
      let years = [];
      for (var i = this.startYear; i <= this.currentYear; i++) {
        years.push(i);
      }
      return years;
    },
    chartInfo() {
      let data = [];
      let stats = [];
      for (let i = this.startYear; i <= this.currentYear; i++) {
        const year = {name: i, color: this.getChartColor(this.years.indexOf(i))};
        this.inspectionDeficiencyCodeGroup.forEach(chart => {
          data.push(chart.years[i])
        });
        year.data = data
        data = [];
        stats.push(year);
      }
      return stats;
    },
    chartCategories() {
      const categories = [];
      this.inspectionDeficiencyCodeGroup.forEach(item => {
        categories.push(item.event)
      });
      return categories;
    }
  },
  methods: {
    ...mapActions(['getInspectionDeficiencyCodeGroup']),
    populateDeficiencyCodeGroup() {
      let years = Object.keys(this.inspectionDeficiencyCodeGroup[0].years);
      this.startYear = years[0];
      this.currentYear = years[years.length - 1];
    },
    getChartColor(index) {
      const colors = ['#bfbfbf', '#a6a6a6', '#7e7e7e', '#d0ad3d', '#fdc92c', '#fdc096', '#ff9e0c', '#ff8035', '#ff6005', '#a92424', '#ff0000'];
      return colors[index];
    },

    async downloadReport(){
      swal.fire({
        'title': `GENERATING DEFICIENCY OVERVIEW REPORT <br>Please wait.....`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      })
      swal.showLoading()
      await axios({
        url: this.downloadExcelUrl.href,
        method: 'GET',
        responseType: 'blob'
      })
        .then(response => {
          swal.close()

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'PSC_DEFICIENCY_OVERVIEW.xlsx'); // Update with your file name
          document.body.appendChild(link);
          link.click();
        }).catch(error => {
          GlobalService.handleError(error, 'DOWNLOAD PSC DEFICIENCY OVERVIEW SERVICE')
          return false
        });
    }
  },
  async created() {
    this.loading = true;
    await this.getInspectionDeficiencyCodeGroup();
    this.populateDeficiencyCodeGroup();
    this.loading = false;
  }
}
</script>

<style scoped>

</style>
