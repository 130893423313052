<template>
  <div id="chart">
    <apexchart type="bar" height="auto" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>

export default {
  name: "PscDeficiencyCodeGroupChart",
  props:{
    series:{
      type:Object | Array,
      default:null,
    },
    categories:{
      type: Array,
      default: null
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: true,
            vertical: true,
            dataLabels: {
              position: 'top',
            },
          }
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          colors: ['#000']
        },
        tooltip: {
          shared: true,
          intersect: false
        },
        fill: {
          colors: [ '#bfbfbf', '#a6a6a6', '#7e7e7e', '#d0ad3d', '#fdc92c', '#fdc096', '#ff9e0c', '#ff8035', '#ff6005', '#a92424', '#ff0000'],
          // colors: ['#bfbfbf', '#a6a6a6', '#ffd966', '#f7caac', '#f4b083', '#ff0000']
          // colors: []
        },
        grid: {
          borderColor: '#e9e9e9',
          position: 'front',
          xaxis: {
            lines: {
              show: true,
            }
          }
        },
        xaxis: {
          categories: this.categories
        },
      },
    }
  },
  methods: {
  },
  async created() {
    // for (let $i = 0; $i < 11; $i++) {
    //   let dec = 12566463 + (414521.7 * $i);
    //   dec = parseInt(dec.toFixed(0));
    //   this.chartOptions.fill.colors.push('#' + (dec).toString(16));
    // }
  }
}
</script>

<style scoped>

</style>
