var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"table text-center table-bordered table-sm text-dark mb-1"},[_c('thead',[_c('tr',{staticClass:"text-center"},[_c('th',{staticClass:"lead e-bg-green-light-3"},[_vm._v("Description")]),_vm._l((_vm.years),function(year,index){return _c('th',{class:{
      'lead bg-bfbfbf' : index ===0,
      'lead bg-a6a6a6' : index ===1,
      'lead bg-ffd966' : index ===2,
      'lead e-bg-yellow' : index ===3,
      'lead bg-f7caac' : index ===4,
      'lead bg-f4b083' : index ===5,
      'lead bg-042454' : index ===6,
      'lead bg-1b4f72' : index ===7,
      'lead bg-056cb4' : index ===8,
      'lead bg-478bcA' : index ===9,
      'lead bg-ff0000' : index ===10,
    }},[_vm._v(" "+_vm._s(year))])})],2)]),_c('tbody',_vm._l((_vm.data),function(item){return _c('tr',[_c('td',[_vm._v(_vm._s(item.event))]),_vm._l((_vm.years),function(year){return _c('td',[_vm._v(" "+_vm._s(item.years[year])+" ")])})],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }